/* global BigInt */
import { useState, useEffect, useCallback } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const loadBlockchainData = useCallback(async () => {
    if (typeof window.ethereum !== 'undefined') {
      try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' })
        setAccount(accounts[0]);
        return (accounts[0]);
      } catch (err) {
        if (err.code === 4001) {
          error("User denied transaction.");
        } else {
          error(err)
          console.log(err);
        }
      }
    } else {
      error("MetaMask is not installed.");
    }
  }, [])
  useEffect(() => {
    loadBlockchainData();
  }, [loadBlockchainData]);

  const error = (message) => {
    toast.error(String(JSON.stringify(message)), {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }

  const presaleContractAddress = "0x8001636A64197B385dfa157c7C74FACF4BF1971b";

  const { ethereum } = window;
  const CHAIN_ID = 137;

  const [ETH, setETH] = useState("");
  const [TTM, setTTM] = useState("");
  const [account, setAccount] = useState('');

  const handleETHChange = (e) => {
    setETH(e.target.value);
    setTTM(e.target.value * (1 / 0.000017));
  }
  const handleTTMChange = (e) => {
    setETH(e.target.value / (1 / 0.000017));
    setTTM(e.target.value);
  }

  async function buy() {
    if (typeof ethereum !== 'undefined') {
      if (await loadBlockchainData()) {
        try {
          const chainId = await ethereum.request({ method: 'eth_chainId' });
          if (Number(chainId) !== CHAIN_ID) {
            await ethereum.request({
              method: 'wallet_switchEthereumChain',
              params: [{ chainId: '0x' + String(CHAIN_ID.toString(16)) }],
            });
          } else {
            if (isValid((BigInt(Math.floor(ETH * 10 ** 18))).toString(16))) {
              await ethereum.request({ method: 'eth_sendTransaction', params: [{ from: account, to: presaleContractAddress, value: (BigInt(Math.floor(ETH * 10 ** 18))).toString(16) }] });
            } else {
              error("You are not eligible to open a Zoin node.")
            }
          }
        } catch (err) {
          if (err.code === 4001) {
            error("User denied transaction.");
          } else {
            error(err)
            console.error(err);
          }
        }
      }
      async function isValid(value_) {
        try {
          await ethereum.estimateGas({ from: account, to: presaleContractAddress, value: value_ })
          return value_;
        } catch (err) {
          return false;
        }
      }

    } else {
      error("MetaMask is not installed.");
    }
  }

  return (
    <div>
      <div className="landing">
        <center>
          <div className="input-wrapper">
            <p className="name">MATIC</p>
            <input type="number" value={ETH} onChange={handleETHChange} className="input"></input>
          </div>
          <div className="input-wrapper">
            <p className="name">TTM</p>
            <input type="number" value={TTM} onChange={handleTTMChange} className="input"></input>
          </div>
          <br />
          <button onClick={() => buy()} className="buy">Buy</button>
        </center>

      </div>
      <ToastContainer />
      <div className="metamask-badge">
        <a href="https://metamask.io/" target="_blank" rel="noreferrer">
          <img alt="" className="metamask-logo" src="assets/metamask.webp"></img>
          <p className="metamask-text">Metamask Compatible</p>
        </a>
      </div>
    </div>

  );
}

export default App;
